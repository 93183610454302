<template>
  <div @wheel.prevent.stop="$emit('wheel', $event)" :class="['design-task-menu-wrap absolute', {'is-preview': task.showPreview && !task.showForm}]">
    <div class="design-task-menu relative px-4 pt-4 pb-6 rounded">
      <div class="d-flex align-center mb-3">
        <UiAvatar
          :src="task.created_by.avatar ? task.created_by.avatar.url : ''"
          :text="task.created_by.first_name"
          size="26"
          class="mr-2"
        />

        <div class="pr-4 overflow-hidden">
          <p class="mb-0 text-body text-no-wrap overflow-hidden text-overflow-ellipsis font-weight-semi-bold">
            {{ task.created_by.first_name }} {{ task.created_by.last_name }}
          </p>
          <div class="text-captions-2">{{ timeDistance(task.created_at) }}</div>
        </div>

        <UiBtn
          v-if="task.showForm"
          icon
          plain
          class="ml-auto mt-n2 mr-n2"
          :disabled="task.loading"
          @click="$emit('closeItemForm')"
        >
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>

      <div v-if="createSubTask && !task.parent.id && taskList.length" class="field-validation d-flex justify-center align-center mb-4 rounded text-captions-1 warning--text text-center">
        Please select a task to assign a sub task
      </div>

      <template v-if="task.showPreview && !task.showForm">
        <p class="mb-5 text-body" v-html="formatToHTMLMentionMessage(task.description, task.tagged_users)"/>

        <UiBtn outlined color="accent" width="100%" @click="$emit('showItemForm')">
          View Task
        </UiBtn>
      </template>

      <ValidationObserver v-else tag="div" ref="form" v-slot="{ handleSubmit }">
        <v-form class="d-flex flex-column" ref="form" lazy-validation @submit.prevent="handleSubmit(submitHandler)">
          <div class="relative mb-3">
            <UiMentionInput
              ref="task_description"
              class="fill-width mr-2"
              :value="formatToHTMLMentionMessage(task.description, task.tagged_users)"
              :content-class="'task-description text-captions-1 rounded overflow-y-auto'"
              :users="getProject.team"
              :disabled="!editEnable || task.loading"
              :menu-class="'task-description-list-menu'"
              :placeholder="'Type description here...'"
              @editMessage="val => task.description = val"
            />

            <UIFilesUploader
              v-if="editEnable"
              :files-formats="$config.filesystem.tasks.attachments.acceptedUploadFormats"
              :max-file-size="$config.filesystem.tasks.attachments.maxUploadFileSize"
              :drag-and-drop="false"
              :disabled="task.loading"
              multiple
              @onChange="addFile"
              class="d-flex align-center absolute input-upload-btn accent--text text-captions-2 text-capitalize cursor-pointer"
            >
              <IconUpload width="14" class="mr-2"/>
              Upload files
            </UIFilesUploader>
          </div>

          <div v-if="task.attachments.length" class="d-flex space-x-4 pt-2 mb-3 overflow-x-auto">
            <div
              v-for="file in task.attachments"
              :key="file.id"
              class="task-attachments-item relative flex-shrink-0 rounded gray-60--text cursor-pointer"
              @click="viewFile(file)"
            >
              <div class="d-flex justify-center align-center fill-width fill-height rounded overflow-hidden">
                <UiFileIcon v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(file.original_name))" :extension="getFileName(file.original_name).ext" width="24"/>
                <img v-else :src="getFileThumbnails(file.thumbnails) || file.url" alt="" class="fill-width">
              </div>

              <button
                v-if="editEnable"
                type="button"
                :disabled="task.loading"
                class="absolute d-flex align-center justify-center"
                @click.stop="removeFile(file.id)"
              >
                <IconCancelCircle width="18"/>
              </button>
            </div>
          </div>

          <UiCheckbox
            v-if="!task.id"
            v-model="createTask"
            checkbox-form="square"
            :disabled="task.loading"
            @change="setComment"
            hide-details
            label="Create Task"
            class="mt-0 mb-3"
          />

          <ValidationProvider class="mb-3" mode="eager" tag="div"
                              name="task name" vid="name" rules="max:255|required" v-slot="{ errors }">
            <div class="mb-2 text-body text-capitalize gray-100--text">Task name</div>
            <div class="d-flex align-center">
              <v-text-field
                v-model="task.name"
                dense
                outlined
                hide-details
                placeholder="Task Name"
                :disabled="!editEnable || task.loading"
                :error="!!errors.length"
              />
              <UiCheckbox
                v-model="createSubTask"
                checkbox-form="square"
                :disabled="!editEnable || task.loading"
                @change="toggleSubTask"
                hide-details
                label="Create as Sub-Task"
                class="my-0 ml-4"
              />
            </div>
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <div v-if="createSubTask" class="mb-3">
            <div class="mb-2 text-body text-capitalize gray-100--text">Parent Task</div>
            <UiAutocomplete
              v-model="task.parent.id"
              :items="taskList"
              :item-value="'id'"
              :item-text="'name'"
              :disabled="!editEnable || task.loading"
              clearable
              :menu-props="{zIndex: zIndexForSelect}"
              placeholder="Select Task"
            />
          </div>

          <div class="mb-3">
            <div class="mb-2 text-body text-capitalize gray-100--text">Assignee</div>
            <UISelectAssignee
              v-model="task.assignee"
              :menuWidth="$vuetify.breakpoint.xsOnly ? 'calc(100% - 32px)' : '470px'"
              :assignee-list="getProject.team"
              :disabled="!editEnable || task.loading"
              :z-index="zIndexForSelect"
            >
              <template v-slot:input="{ assignedUser, isOpen }">
                <div :class="['text-input-block assignee-select d-flex align-center px-3 rounded text-captions-1 gray-60--text', {'is-open--input': isOpen}]">
                  <UiAvatar
                    :src="assignedUser.user.avatar ? assignedUser.user.avatar.url : ''"
                    :text="assignedUser.user.first_name"
                    size="18"
                    class="mr-2"
                  />
                  <span class="overflow-hidden text-overflow-ellipsis text-no-wrap">
                      {{ assignedUser.user.first_name }} {{ assignedUser.user.last_name }}
                    </span>
                  <IconChevronDown class="ml-auto icon-transition"/>
                </div>
              </template>
            </UISelectAssignee>
          </div>

          <div class="d-flex space-x-4 mb-3">
            <div class="fill-width">
              <div class="mb-2 text-body text-capitalize gray-100--text">Due date</div>
              <UIDatePicker
                v-model="task.due_date"
                :disabled-date="disabledBeforeToday"
                :disabled="!editEnable || task.loading"
                :append-to-body="false"
                @save="(data) => {task.due_date = data.value}"
                style="width: 100%!important;"
              >
                <template v-slot:input>
                  <div class="text-input-block d-flex align-center fill-width px-3 rounded text-captions-1 gray-60--text">
                    <IconCalendarSolid class="mr-2"/>
                    <span>{{ formatDate(task.due_date, 'MMM dd, yyyy') }}</span>
                    <IconChevronDown class="ml-auto icon-transition"/>
                  </div>
                </template>
              </UIDatePicker>
            </div>

            <ValidationProvider class="fill-width" tag="div" rules="required" name="priority" vid="priority" v-slot="{ errors }">
              <div class="mb-2 text-body text-capitalize gray-100--text">Priority</div>
              <UiPrioritySelect
                v-model="task.priority"
                :items="priorities"
                :text-label="$config.tasks.priorityLabels[task.priority]"
                append-icon=""
                dense
                hide-details
                :menu-props="{nudgeBottom: 10, nudgeRight: 12, minWidth: '228px', zIndex: zIndexForSelect}"
                :disabled="!editEnable || task.loading"
                :error="!!errors.length"
                class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
              >
                <template #append>
                  <IconChevronDown class="ml-auto icon-transition"/>
                </template>
              </UiPrioritySelect>
              <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
            </ValidationProvider>
          </div>

          <div class="d-flex space-x-4 mb-5">
            <ValidationProvider class="fill-width" tag="div" rules="required" name="status" vid="status" v-slot="{ errors }">
              <div class="mb-2 text-body text-capitalize gray-100--text">Status</div>
              <UiTaskStatusSelect
                v-model="task.status"
                :items="statuses"
                :text-label="$config.tasks.statusLabels[task.status]"
                append-icon=""
                dense
                hide-details
                :menu-props="{nudgeBottom: 10, nudgeRight: 12, minWidth: '146px', zIndex: zIndexForSelect}"
                :disabled="!editEnable ||task.loading"
                @change="updateTaskStatus"
                :error="!!errors.length"
                class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
              >
                <template #append>
                  <IconChevronDown class="ml-auto icon-transition"/>
                </template>
              </UiTaskStatusSelect>
              <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
            </ValidationProvider>

            <div class="fill-width">
              <div class="mb-2 text-body text-capitalize gray-100--text">Reminder</div>
              <UIDatePicker
                v-model="task.reminder"
                :disabled-date="disabledBeforeDueDate"
                :disabled="disabledNotify || !editEnable || task.loading"
                :append-to-body="false"
                style="width: 100%!important;"
              >
                <template v-slot:header="{ date }">
                  <div class="ui-due-datepicker-header font-weight-semi-bold">
                    <IconClockSolid class="mr-2" style="width: 20px; height: 20px;"/>
                    Reminder
                    <span class="ml-auto font-weight-semi-bold">{{ formatDate(date, 'MMM dd, yyyy') }}</span>
                  </div>
                </template>
                <template v-slot:input>
                  <div class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text">
                    <IconClockSolid class="mr-2"/>
                    <span>{{ formatDate(task.reminder, 'MM/dd/yyyy') }}</span>
                    <IconChevronDown class="ml-auto icon-transition"/>
                  </div>
                </template>
              </UIDatePicker>
            </div>

            <div class="fill-width">
              <div class="mb-2 text-body text-capitalize gray-100--text">Time</div>
              <UIDatePicker
                v-model="task.reminder"
                :time-picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30',
                }"
                format="hh:mm a"
                type="time"
                placeholder="hh:mm a"
                hide-header
                hide-footer
                popup-class="task-reminder-timepicker"
                :disabled="disabledNotify || !editEnable || task.loading"
                :append-to-body="false"
                style="width: 100%!important;"
              >
                <template v-slot:input>
                  <div class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text">
                    <IconSandClock class="mr-2"/>
                    <span>{{ formatDate(task.reminder, 'hh:mm aaa') }}</span>
                    <IconChevronDown class="ml-auto icon-transition"/>
                  </div>
                </template>
              </UIDatePicker>
            </div>
          </div>

          <div class="d-flex justify-center flex-wrap justify-sm-end">
            <UiBtn
              v-if="task.id !== null && $vuetify.breakpoint.smAndUp"
              icon
              plain
              :disabled="!editEnable || task.loading"
              @click="$emit('deleteTask')"
              class="mr-auto ml-n1"
            >
              <IconDelete width="18" class="gray-60--text"/>
            </UiBtn>

            <UiBtn
              outlined
              width="94"
              color="gray-60"
              class="mr-2"
              @click="$emit('closeItemForm')"
              style="background-color:#fff;"
            >
              Cancel
            </UiBtn>

            <UiBtn
              v-if="editEnable"
              type="submit"
              width="94"
              color="accent"
              :loading="task.loading"
            >
              {{ task.id === null ? 'Create Task' : 'Update Task' }}
            </UiBtn>
          </div>

        </v-form>
      </ValidationObserver>

      <div v-if="dataLoading" class="d-flex justify-center align-center absolute inset-0" style="background-color:rgba(255, 255, 255, .5);">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {isSameDay, format, formatDistanceToNow} from 'date-fns';
import {formatToHTMLMentionMessage, getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import UIDatePicker from "@/components/UI/UIDatePicker";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";
import UISelectAssignee from "@/components/UI/UISelectAssignee";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UiPrioritySelect from "@/components/UI/UiPrioritySelect";
import UiTaskStatusSelect from "@/components/UI/UiTaskStatusSelect";
import UiAutocomplete from "@/components/UI/UiAutocomplete";
import UiMentionInput from "@/components/UI/UiMentionInput";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: 'TaskForm',
  components: {
    UiFileIcon,
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCalendarSolid: () => import('@/components/icons/IconCalendarSolid'),
    IconClockSolid: () => import('@/components/icons/IconClockSolid'),
    IconSandClock: () => import('@/components/icons/IconSandClock'),

    UIDatePicker,
    UiAvatar,
    UiBtn,
    UISelectAssignee,
    UIFilesUploader,
    UiCheckbox,
    UiPrioritySelect,
    UiTaskStatusSelect,
    UiAutocomplete,
    UiMentionInput,
  },
  props: {
    task: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      dataLoading: false,
      modal: true,
      createTask: true,
      createSubTask: false,
      priorities: Object.values(this.$config.tasks.priority),
      statuses: Object.values(this.$config.tasks.status),
      taskList: [],
      zIndexForSelect: 12
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProject',
      'getPermission',
      'getProjectPermissionData',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerTasks',
    ]),
    disabledNotify() {
      return isSameDay(new Date(), this.task.due_date);
    },
    editEnable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;

      if (this.task.id === null) {
        return this.getPermission(this.$route.params.project_id).design['can-create-task-design'];
      }

      return this.task.created_by.id === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    },
  },
  methods: {
    getFileThumbnails,
    getFileName,
    getFileExtension,
    ...mapActions([
      'setActiveTaskInTaskTab',
      'setActiveTab'
    ]),
    formatToHTMLMentionMessage,
    formatDate(date, dateFormat) {
      return format(new Date(date), dateFormat);
    },
    timeDistance(date) {
      const res = formatDistanceToNow(new Date(date), {addSuffix: true})
      return res.replace('about ', '')
    },
    disabledBeforeToday(date) {
      const today = new Date().setHours(0, 0, 0, 0);
      return date < today;
    },
    disabledBeforeDueDate(date) {
      const today = new Date().setHours(0, 0, 0, 0);
      const dueDate = new Date(this.task.due_date).setHours(0, 0, 0, 0,);
      return date < today || date >= dueDate;
    },
    viewFile({type, url, original_name}) {
      this.$store.dispatch('openModal', {
        modalName: 'previewFileModal',
        data: {
          type, url, original_name
        }
      });
    },
    removeFile(id) {
      this.task.attachments = this.task.attachments.filter(file => file.id !== id);
    },
    addFile(files) {
      files.forEach((file, idx) => {
        const reader = new FileReader();

        const newFile = {
          id: new Date().getTime() + idx,
          type: file.type,
          original_name: file.name,
          url: '',
          file
        }

        reader.onload = () => {
          newFile.url = reader.result;
          this.task.attachments.push(newFile);
        }

        reader.readAsDataURL(file);
      });
    },
    async toggleSubTask() {
      if (this.createSubTask) {
        await this.loadTasks();
        this.dataLoading = false;
      } else {
        this.task.parent.id = null;
      }
    },
    async loadTasks() {
      this.dataLoading = true;
      try {
        const res = await this.$api.task.list(`projects/${this.getProject.id}/tasks`, {
          count: -1,
          project_tag_id: this.task.project_tag_id,
          project_style_id: this.task.project_style_id,
        });

        this.taskList = res.data.filter(task => task.id !== this.task.id);
      } catch (error) {
        console.error(error);
      }
    },
    submitHandler() {
      if (!this.editEnable || this.task.loading) return;

      const {message, users} = this.$refs.task_description.getFormattedMessageData();

      this.task.description = message;
      this.task.tagged_users = users;

      if (this.task.id !== null) {
        this.$emit('updateTask', this.task);
      } else {
        this.$emit('createTask', this.task);
      }
    },
    async updateTaskStatus() {
      if (!this.task.id || this.task.id && this.task.created_by.id === this.getAuthId) return;
      this.dataLoading = true;

      try {
        const res = await this.$api.task.updateStatus(this.$route.params.project_id, this.task.id, {status: this.task.status});

        if (this.getProjectSideDrawerTasks.data.length) {
          await this.$store.dispatch('setProjectSideDrawerTabData', {
            tab: 'tasks',
            data: this.getProjectSideDrawerTasks.data.map(sdTask => {
              if (this.task.parent && this.task.parent.id && this.task.parent.id && sdTask.id) {
                return {
                  ...sdTask,
                  children: sdTask.children.map(subTask => subTask.id === this.task.id ? res.data : subTask),
                }
              } else if (sdTask.id === res.data.id) {
                return res.data
              } else {
                return sdTask
              }
            }),
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.dataLoading = false;
      }
    },
    setComment() {
      const {message, users} = this.$refs.task_description.getFormattedMessageData();

      this.task.type = this.createTask ? 'task' : 'comment';
      this.task.description = message;
      this.task.tagged_users = users.map(user => ({id: user.id, first_name: user.userName}));
    }
  }
}
</script>

<style scoped lang="scss">
.design-task-menu-wrap {
  width: 510px;
  cursor: auto;

  &.top {
    top: calc(100% - 38px);
  }

  &.left {
    left: 100%;
    .design-task-menu {
      margin-left: 6px;
    }
  }

  &.bottom {
    bottom: -6px;
  }

  &.right {
    right: calc(100% + 6px);
  }

  &.is-preview {
    width: 386px;
  }

  .design-task-menu {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    background-color: var(--v-gray-10-base);
  }

  .field-validation {
    height: 30px;
    border: 1px solid currentColor;
  }

  .input-upload-btn {
    right: 10px;
    bottom: 8px;
  }

  .task-attachments-item {
    height: 54px;
    width: 54px;
    border: 1px solid var(--v-gray-30-base);
    background-color: var(--v-gray-10-base);
    button {
      top: -8px;
      right: -10px;
    }
  }

  .v-text-field {
    background-color: var(--v-gray-0-base);
  }

  .text-input-block {
    height: 30px;
    border: 1px solid var(--v-gray-30-base);
    background-color: var(--v-gray-0-base);

    span {
      padding-top: 1px;
    }

    svg {
      fill: transparent;
      &:nth-child(1) {
        width: 14px!important;
        height: 14px!important;
      }
    }

    svg:nth-child(3),
    .v-input__append-inner svg {
      width: 12px!important;
    }

    .v-select__selections {
      span {
        color: var(--v-gray-60-base);
      }
    }
  }

  .is-disabled--input {
    pointer-events: none;
  }

  .is-open--input .text-input-block,
  .is-open--input.text-input-block {
    & > svg:first-child {
      color: var(--v-accent-base);
    }
    & > svg:last-child {
      transform: rotate(180deg);
    }
    border-color: var(--v-accent-base);
  }

  .v-input--is-focused.text-input-block {
    border-color: var(--v-accent-base);

    .v-input__append-inner svg {
      transform: rotate(180deg);
    }
  }
}

::v-deep .task-description {
  height: 72px;
  padding: 8px 12px;
  background-color: var(--v-gray-0-base);
  border: 1px solid var(--v-gray-30-base);
  color: var(--v-gray-60-base);

  &:empty::before {
    color: var(--v-gray-60-base);
  }

  &:focus {
    border-color: var(--v-accent-base);
  }
}
</style>

<style lang="scss">
.task-description-list-menu {
  transform: translateY(-115%);
}
</style>
