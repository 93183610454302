<template>
  <div
    :class="['mb-6', {'cursor-pointer': !comment.readed_at}]"
    @click="!comment.readed_at ? $emit('toggleCommentReadStatus', comment) : $event.preventDefault()"
  >
    <div class="d-flex align-center mb-2">
      <UiAvatar
        :src="comment.created_by.avatar ? comment.created_by.avatar.url : ''"
        :text="comment.created_by.first_name"
        size="26"
        class="mr-2"
      />

      <div class="pr-4 relative" style="width: 82%;">
        <div class="d-flex overflow-hidden">
          <p class="relative mb-0 text-body text-no-wrap overflow-hidden text-overflow-ellipsis font-weight-semi-bold">
            {{ comment.created_by.first_name }} {{ comment.created_by.last_name }}
          </p>
          <IconCircleFilled v-if="!comment.readed_at" width="10" class="flex-shrink-0 ml-2 accent--text"/>
        </div>
        <div class="text-captions-2">{{ timeDistance(comment.edited ? comment.updated_at : comment.created_at) }}</div>
      </div>

      <UiMenu
        allow-overflow
        offset-y
        left
        min-width="132"
        :nudge-bottom="10"
        :nudge-right="50"
        content-class="thread-comment-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <UiBtn v-bind="attrs" v-on="on" icon plain class="ml-auto mt-n3">
            <IconDotsH width="18" class="gray-60--text"/>
          </UiBtn>
        </template>

        <v-list nav dense color="gray-10">
          <v-list-item v-if="comment.created_by.id === getAuthId" @click="$emit('deleteComment', comment)">
            <v-list-item-content class="py-1">
              <v-list-item-title>
                {{ !comment.parent_id ? 'Delete Thread' : 'Delete comment'}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :disabled="!comment.readed_at" @click="$emit('toggleCommentReadStatus', comment)">
            <v-list-item-content class="py-1">
              <v-list-item-title>Mark as unread</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="comment.created_by.id === getAuthId" @click="$emit('editComment', comment)">
            <v-list-item-content class="py-1">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </UiMenu>
    </div>

    <div class="d-flex">
      <p  v-html="formatToHTMLMentionMessage(comment.description, comment.tagged_users)" class="text-body mb-0 gray-100--text flex-1" style="word-break: break-word;"/>
      <span class="text-captions-2 gray-90--text" v-if="comment.edited">edited</span>
    </div>


    <div v-if="comment.attachments.length" class="d-flex space-x-4 pt-2 overflow-x-auto">
      <div
        v-for="file in comment.attachments"
        :key="file.id"
        class="comment-attachments-item relative flex-shrink-0 rounded gray-60--text cursor-pointer"
        @click="viewFile(file)"
      >
        <div class="d-flex justify-center align-center fill-width fill-height rounded overflow-hidden">
          <UiFileIcon v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(file.original_name))" :extension="getFileName(file.original_name).ext" width="24"/>
          <img v-else :src="getFileThumbnails(file.thumbnails) || file.url" alt="" class="fill-width">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDistanceToNow} from "date-fns";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import {mapGetters} from "vuex";
import {formatToHTMLMentionMessage, getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: 'Comment',
  components: {
    UiFileIcon,
    IconCircleFilled: () => import('@/components/icons/IconCircleFilled'),
    IconDotsH: () => import('@/components/icons/IconDotsH'),

    UiAvatar,
    UiBtn,
    UiMenu,
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProject',
    ])
  },
  mounted() {
    if(this.comment && !this.comment.readed_at) {
      this.$emit('toggleCommentReadStatus', this.comment)
    }
  },
  methods: {
    getFileThumbnails,
    getFileName,
    getFileExtension,
    formatToHTMLMentionMessage,
    timeDistance(date) {
      const res = formatDistanceToNow(new Date(date), {addSuffix: true})
      return res.replace('about ', '')
    },
    viewFile({type, url, original_name}) {
      this.$store.dispatch('openModal', {
        modalName: 'previewFileModal',
        data: {
          type, url, original_name
        }
      });
    },
  }
}
</script>
