<template>
  <div style="width: calc(100% - 42px)">
    <v-list-item-group v-model="internalValue" mandatory
                       class="d-flex fill-width overflow-y-auto mt-n4 mx-n4 mb-n1 px-4 pt-4">
      <v-list-item
        v-for="(module) in currentModules"
        :key="module.id"
        :value="module.id"
        :ripple="false"
        :disabled="module.disabled"
        active-class="tab-btn--active"
        :class="['tab-btn flex fill-width text-captions-1 overflow-hidden mb-1', {
          'open-workflow': getProject.open_workflow,
          'completed': !getProject.open_workflow && module.status === $config.project.moduleStatus.finished,
          'tab-btn--active-small': currentModules.length >= 6
        }]"
      >
        <UiProjectModuleIcon width="14" :icon="module.icon" class="module-icon mr-2 flex-shrink-0"/>

        <UiChangeNameInput
          v-if="editModuleModal && editedModule.id === module.id"
          v-model="editedModule.title"
          placeholder="Enter name"
          :rules="'required|min:3|max:128'"
          @onSave="updateModule"
          @onClose="editModuleModal = false"
        />
        <template v-else>
          <span
            class="text-no-wrap text-overflow-ellipsis overflow-hidden gray-60--text"
            :class="{
              'mr-2': currentModules.length >=6 && module.id === internalValue
            }"
          >
            {{ module.title }}
          </span>
        </template>


        <UiMenu
          bottom
          left
          offset-y
          :nudge-bottom="10"
          :min-width="146"
          v-if="showActionMenu(module)"
          :z-index="10"
        >
          <template v-slot:activator="{ on, attrs }">
            <UiBtn v-bind="attrs" v-on="on" icon width="24" height="24" color="gray-60" class="ml-auto mr-n2">
              <IconDotsV width="16"/>
            </UiBtn>
          </template>

          <v-list nav dense color="gray-10">
            <div v-if="getPermission($route.params.project_id).design['can-edit-module-name']"
                 class="v-list-item cursor-pointer" @click="openModuleModal(module)">
              <div class="d-flex align-center text-captions-1">
                <IconPen width="15" class="mr-2 gray-60--text"/>
                Change Name
              </div>
            </div>
            <div v-if="showEditAccessButton" :class="['v-list-item', 'cursor-pointer']"
                 @click="openEditAccessModal(module)">
              <div class="d-flex align-center text-captions-1">
                <IconMember width="15" class="mr-2 gray-60--text"/>
                Edit Access
              </div>
            </div>
            <div v-if="module.total_files" :class="['v-list-item', 'cursor-pointer']"
                 @click="downloadSummary(module)">
              <div class="d-flex align-center text-captions-1">
                <IconDownload width="15" class="mr-2 gray-60--text"/>
                Download Summary
              </div>
            </div>
          </v-list>
        </UiMenu>
      </v-list-item>
    </v-list-item-group>

    <!--    <v-dialog-->
    <!--      v-model="editModuleModal"-->
    <!--      max-width="434"-->
    <!--      :persistent="loading"-->
    <!--      content-class="rename-workflow-module-modal"-->
    <!--    >-->
    <!--      <div class="py-5 pb-6 px-5 gray-0">-->
    <!--        <div class="d-flex align-center justify-space-between mb-3">-->
    <!--          <h5 class="text-title-2">Rename Section</h5>-->

    <!--          <UiBtn fab color="gray-0" @click="editModuleModal = false" class="mr-n2">-->
    <!--            <IconCancel width="16" class="gray-80&#45;&#45;text"/>-->
    <!--          </UiBtn>-->
    <!--        </div>-->

    <!--        <ValidationObserver ref="form">-->
    <!--          <v-form @submit.prevent="updateModule">-->
    <!--            <ValidationProvider class="mb-5" mode="eager" tag="div"-->
    <!--                                name="name" vid="title" rules="required|min:3|max:128" v-slot="{ errors }">-->
    <!--              <v-text-field-->
    <!--                v-model="editedModule.title"-->
    <!--                dense-->
    <!--                outlined-->
    <!--                hide-details-->
    <!--                autofocus-->
    <!--                placeholder="Enter name"-->
    <!--                :disabled="loading || !getPermission($route.params.project_id).specification['can-edit-specification']"-->
    <!--                :error="!!errors.length"-->
    <!--                class=""-->
    <!--                style="border-radius: 2px;"-->
    <!--              />-->
    <!--              <div v-if="errors.length" class="mt-1 text-captions-1 error&#45;&#45;text">{{ errors[0] | capitalize }}</div>-->
    <!--            </ValidationProvider>-->

    <!--            <p class="mb-2 text-body font-weight-semi-bold">Change Icon</p>-->
    <!--            <v-list-item-group-->
    <!--              v-model="editedModule.icon"-->
    <!--              mandatory-->
    <!--              class="justify-space-between mb-6 pt-3 px-4 fill-width"-->
    <!--            >-->
    <!--              <v-list-item-->
    <!--                v-for="moduleIcon in $config.project.moduleIcons"-->
    <!--                :key="moduleIcon.name"-->
    <!--                :value="moduleIcon.name"-->
    <!--                :disabled="loading"-->
    <!--                class="px-0 justify-center"-->
    <!--              >-->
    <!--                <UiProjectModuleIcon :icon="moduleIcon.name"/>-->
    <!--              </v-list-item>-->
    <!--            </v-list-item-group>-->

    <!--            <div class="d-flex justify-end">-->
    <!--              <UiBtn type="submit" color="accent" class="mr-2" :loading="loading">-->
    <!--                Rename-->
    <!--              </UiBtn>-->

    <!--              <UiBtn outlined color="accent" @click="editModuleModal = false" :disabled="loading">-->
    <!--                Cancel-->
    <!--              </UiBtn>-->
    <!--            </div>-->
    <!--          </v-form>-->
    <!--        </ValidationObserver>-->
    <!--      </div>-->
    <!--    </v-dialog>-->
<!--    <EditModuleAccessModal v-if="getModal('editModuleAccessModal').data.project"/>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon";
// import EditModuleAccessModal from "@/components/modals/EditModuleAccessModal.vue";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput.vue";

export default {
  name: "ModulesNavigation",
  components: {
    UiChangeNameInput,
    // EditModuleAccessModal,
    IconDotsV: () => import('@/components/icons/IconDotsV'),
    IconPen: () => import('@/components/icons/IconPen'),
    // IconCancel: () => import('@/components/icons/IconCancel'),
    IconMember: () => import('@/components/icons/IconMember'),
    IconDownload: () => import("@/components/icons/IconDownload"),
    UiBtn,
    UiMenu,
    UiProjectModuleIcon,
  },
  props: {
    modules: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      editModuleModal: false,
      editAccessModal: false,
      loading: false,
      editedModule: {
        id: -1,
        title: '',
        icon: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getProjectPermissionData',
      'getProject',
      'getModal'
    ]),
    internalValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    },
    currentModules() {
      const noApprovalModules = this.modules.reduce((acc, module) => {
        if (module.approve) {
          acc.push({
            ...module,
            disabled: !module.approve
          })
        }
        return acc
      }, [])
      const approvalModules = this.modules.filter((module) => !module.approve).map((module, idx, arr) => {
        return {
          ...module,
          disabled: !this.getProject.open_workflow && arr.slice(0, idx ?? arr.length).some(m => m.status !== this.$config.project.moduleStatus.finished)
        }
      })
      return [...noApprovalModules, ...approvalModules].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)
    },
    showEditAccessButton() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.getProject.user.id === this.getAuthId ||
        this.getProjectPermissionData(this.getProject.id).roles.some(role => role === projectOwner || role === projectManager)
      )
    }
  },
  methods: {
    openModuleModal(module) {
      this.editModuleModal = true;
      this.editedModule = {
        id: module.id,
        title: module.title
      };
    },
    openEditAccessModal(module) {
      this.$store.dispatch('openModal', {
        modalName: 'editModuleAccessModal',
        data: {
          module_id: module.id,
          module: module,
          project: this.getProject
        },
      });
    },
    async updateModule() {
      this.loading = true;
      try {
        await this.$api.project.updateProjectModuleTitle(this.editedModule.id, {title: this.editedModule.title});
        const modules = this.modules.map(module => {
          if (module.id === this.editedModule.id) {
            return {
              ...module,
              title: this.editedModule.title,
            }
          }
          return module;
        });

        await this.$store.dispatch('setDesignModules', modules);
        await this.$store.dispatch('setProject', {
          ...this.getProject, modules: this.getProject.modules.map(module => {
            if (module.id === this.editedModule.id) {
              return {
                ...module,
                title: this.editedModule.title,
              }
            }
            return module;
          })
        })
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.editModuleModal = false;
      }
    },
    async downloadSummary(module) {
      try {
        const {data} = await this.$api.project.downloadSummary(this.getProject.id, module.id)
        if(data?.url) {
          window.open(data.url, 'Download');
        }
      } catch (error) {
        console.error(error)
      }
    },
    showActionMenu(module) {
      return this.getPermission(this.getProject.id).design['can-edit-module-name'] || this.getPermission(this.getProject.id).design['can-delete-style-design'] || this.showEditAccessButton || module.total_files
    },
  },
}
</script>

<style scoped lang="scss">
.tab-btn {
  flex-shrink: initial;
  flex-grow: unset;
  flex-basis: unset;
  min-height: 30px;
  min-width: 120px;
  border-radius: 5px 5px 0 0;
  border-bottom: 3px solid var(--v-gray-30-base);
  background: var(--v-gray-0-base);

  &.open-workflow {
    border-bottom-color: transparent;
  }

  &:before, &:after {
    display: none;
  }

  &--active {
    flex-shrink: 0;
    width: 278px;
  }
  &--active-small.tab-btn--active {
    width: fit-content;
    max-width: 278px;
  }

  &--active, &:hover {
    border-bottom-color: var(--v-accent-base) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  &.completed {
    border-bottom-color: var(--v-success-base) !important;

    .module-icon {
      color: var(--v-success-base);
    }
  }

  .module-icon {
    color: var(--v-gray-60-base);
  }
}

.rename-workflow-module-modal {
  .v-list-item-group {
    display: grid;
    grid-template-columns: repeat(8, 20px);
    padding-bottom: 2px;
    border: 1px solid var(--v-gray-30-base);
  }

  .v-list-item {
    flex-basis: unset;
    flex-grow: unset;
    min-height: 20px;
    margin-bottom: 10px;
    color: var(--v-gray-60-base) !important;
    border-bottom: 2px solid transparent;

    &:hover,
    &.v-list-item--active {
      color: var(--v-accent-base) !important;
      border-color: currentColor;
    }

    &::before {
      display: none;
    }
  }
}
</style>
